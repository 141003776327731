import React, { useState, useEffect } from 'react';
import loadable from '@loadable/component';

import { getUserProfile } from '../../services/userProfile.api';
import { getUserAddress } from '../../services/address.api';
import { getStoredCard } from '../../services/card.api';
import { getVouchers, addVoucherToBasket } from '../../services/voucher.api';
import { StoredCard, VoucherResponse } from '../../services/models';
import Loader from '../../components/common/Loader';
import useHasMounted from '../../hooks/usesHasMounted';
import { usePageLoaderContext } from '.';

const AccountBanner = loadable(() =>
  import('../../components/Account/AccountBanner'),
);
const UserDetails = loadable(() =>
  import('../../components/Account/UserDetails'),
);
const BillingInfo = loadable(() =>
  import('../../components/Account/BillingInfo'),
);
const DeliveryInfo = loadable(() =>
  import('../../components/Account/DeliveryInfo'),
);
const Vouchers = loadable(() => import('../../components/Account/Vouchers'));

const initialState = {
  userDetails: {
    isSocialUser: false,
    firstName: '',
    lastName: '',
    email: '',
  },
  userAddress: [],
  storedCard: {} as StoredCard,
  deliveryDetails: null,
};

export default () => {
  const [accountInfo, setAccountInfo] = useState(initialState);
  const { setShowPageLoader, showPageLoader } = usePageLoaderContext();
  const hasMounted = useHasMounted();
  const [vouchers, setVouchers] = useState<VoucherResponse[]>([]);

  const fetchVouchers = async () => {
    setShowPageLoader(true);
    setVouchers(await getVouchers());
    setShowPageLoader(false);
  };

  const addVoucher = async voucherCode => {
    const voucherDetails = await addVoucherToBasket(voucherCode);
    if (voucherDetails.status == 'failure') {
      // setStatus({
      //   message: 'Invalid voucher code.',
      //   type: StatusType.error,
      // });
    }
    if (voucherDetails.status == 'success') {
      fetchVouchers();
    }
  };

  const handleApplyVoucher = voucherCode => {
    setShowPageLoader(true);
    addVoucher(voucherCode);
    setShowPageLoader(false);
  };

  const fetchAccountInfo = async () => {
    setShowPageLoader && setShowPageLoader(true);
    const [userDetails, userAddress, storedCard] = await Promise.all([
      getUserProfile(),
      getUserAddress(),
      getStoredCard(),
    ]);
    const { first_name, last_name, email, is_social_user } = userDetails;
    setAccountInfo({
      userDetails: {
        isSocialUser: is_social_user,
        firstName: first_name,
        lastName: last_name,
        email: email?.trim(),
      },
      userAddress,
      storedCard: storedCard || ({} as StoredCard),
      deliveryDetails: null,
    });
    setShowPageLoader && setShowPageLoader(false);
  };

  useEffect(() => {
    fetchAccountInfo();
    fetchVouchers();
  }, []);

  if (!hasMounted) {
    return null;
  }

  return (
    <>
      {showPageLoader ? <Loader /> : null}
      <div className="dashboard-wrapper">
        <AccountBanner />
        <div className="dash-contents">
          <div className="container-fluid">
            <div className="def-w-max">
              <div className="acc-info-main">
                <div className="row">
                  <div className="col-lg-7 col-12">
                    <UserDetails
                      details={accountInfo.userDetails}
                      fetchAccountInfo={fetchAccountInfo}
                    />
                  </div>
                  <div className="col-lg-5 col-12">
                    <BillingInfo storedCard={accountInfo.storedCard} />
                  </div>
                  <div className="col-12">
                    <DeliveryInfo
                      addresses={accountInfo.userAddress}
                      fetchAccountInfo={fetchAccountInfo}
                    />
                  </div>
                  <div className="col-12">
                    <Vouchers
                      vouchers={vouchers}
                      handleApplyVoucher={handleApplyVoucher}
                      fetchVouchers={fetchVouchers}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="spacer-50" />
    </>
  );
};
